import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Banners from 'bv-banners';
import { Spinner } from 'bv-components';

import getMessageHandler from './get_message_handler';

const Launch = ({ url }) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const messageHandler = getMessageHandler({ setHeight });

    window.addEventListener('message', messageHandler, false);
    return () => window.removeEventListener('message', messageHandler);
  }, []);

  return (
    <>
      <Banners className="lottery-banner-wrapper" location="Lottery" />
      {!height && <Spinner />}
      <iframe
        src={url}
        height={`${height}px`}
        width="100%"
        style={{ border: 'none' }}
      />
    </>
  );
};

Launch.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Launch;
