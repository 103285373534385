import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { replaceFragment } from 'bv-helpers/location';

const LoginRedirect = ({ afterLoginPath }) => {
  useEffect(() => {
    window.VC.afterLoginRedirect = afterLoginPath;
    // Cannot use Redirect component because the route is not handled by react yet
    replaceFragment('login');
  }, []);

  return null;
};

LoginRedirect.propTypes = {
  afterLoginPath: PropTypes.string.isRequired,
};

export default LoginRedirect;
