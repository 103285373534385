import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { isDesktop, v as bvVar } from 'bv';
import { getParameter } from 'bv-helpers/location';

import { fetchLaunchParametersJson } from 'CasinoV2/api';
import DesktopLaunch from './desktop/launch';
import MobileLaunch from './mobile/launch';

const LaunchContainer = () => {
  // Fetch game details
  const [launchDetails, loading] = useFetch(() => (
    fetchLaunchParametersJson(bvVar('lottolandGameId'), {
      backlink: getParameter('backlink'),
    })
  ));

  if (loading) return <Spinner />;

  const Launch = isDesktop() ? DesktopLaunch : MobileLaunch;

  return <Launch url={launchDetails.url} />;
};

export default LaunchContainer;
