import PropTypes from 'prop-types';

import { Spinner } from 'bv-components';

const RedirectToUrl = ({ url }) => {
  window.location.replace(url);
  // Show a Spinner while the redirected page loads
  return <Spinner />;
};

RedirectToUrl.propTypes = {
  url: PropTypes.string.isRequired,
};

export default RedirectToUrl;
