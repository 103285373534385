import { setFragment } from 'bv-helpers/location';

export default ({ setHeight }) => ({ data }) => {
  switch (data.type) {
    case 'scrollTop':
      window.scrollTo(0, 0);
      break;
    case 'resize':
      setHeight(data.height);
      break;
    case 'topUp':
      setFragment('quick_deposit');
      break;
    case 'userLogin':
      window.VC.afterLoginRedirect = `/lottery?backlink=${data.backlink}`;
      setFragment('login');
      break;
    default:
  }
};
