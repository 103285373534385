import { getParameter } from 'bv-helpers/location';
import LoginRedirect from 'CasinoV2/features/shared/components/login_redirect';

const Login = () => (
  <LoginRedirect
    afterLoginPath={`/lottery?backlink=${getParameter('backlink')}`}
  />
);

export default Login;
