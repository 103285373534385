import { ContentLayout } from 'bv-components';

import App from './components/app';

const LottolandComponent = () => (
  <ContentLayout showTabs>
    <App />
  </ContentLayout>
);

export default LottolandComponent;
