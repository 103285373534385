import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { selectLoggedIn } from 'session-selectors';

import Login from './login';
import LaunchContainer from './launch_container';

const LottoLandApp = () => {
  const loggedIn = useSelector(selectLoggedIn);

  return (
    <Switch>
      {!loggedIn && <Route path="/lottery/login" component={Login} />}
      <Route path="/lottery" component={LaunchContainer} />
    </Switch>
  );
};

export default LottoLandApp;
